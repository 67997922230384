/* eslint-disable no-param-reassign */
import { createApp } from 'vue';
import App from '@/App.vue';

import router from '@/router';

import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from '@/firebaseConfig';

import DKToast from 'vue-dk-toast';
import toastConfig from '@/toastConfig';

import '@/assets/styles/scss/main.scss';
import '@/assets/styles/css/tailwind.css';
import { VUE_QUERY_CLIENT, QueryClient } from 'vue-query';

firebase.initializeApp(firebaseConfig);
firebase.auth().onAuthStateChanged(user => {
	if (user) {
		localStorage.setItem(
			'signedInAdmin',
			JSON.stringify({
				id: user.uid,
				email: user.email,
				displayName: user.displayName,
				providerData: user.providerData,
				photoURL: user.photoURL
			})
		);
	} else {
		localStorage.removeItem('signedInAdmin');
	}
});

export const queryClient = new QueryClient();
queryClient.mount();

const app = createApp(App)
	.provide(VUE_QUERY_CLIENT, queryClient)
	.use(DKToast, toastConfig)
	.use(router);

app.directive('number', {
	// When the bound element is mounted into the DOM...
	/**
	 * @param {HTMLInputElement} el
	 * @param {import('vue').DirectiveBinding<{min:number, max:number}>} binding
	 */
	mounted(el, binding) {
		el.oninput = e => {
			let numberValue = Number(el.value.replace(/\D/g, ''));
			const options = binding.value;
			if (options?.min) {
				numberValue = Math.max(numberValue, options?.min);
			}
			if (options?.max) {
				numberValue = Math.min(numberValue, options?.max);
			}
			el.value = `${numberValue}`;
		};
	}
});

app.mount('#app');
