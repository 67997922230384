import { createWebHistory, createRouter } from 'vue-router';

const history = createWebHistory();

const router = createRouter({
	history,
	routes: [
		{
			path: '',
			component: () => import('@/layouts/FullLoginPage.vue'),
			meta: {
				isRequiredAuthorize: false
			},
			children: [
				{
					path: '',
					name: 'login',
					component: () => import('@/views/LoginForm.vue')
				},
				{
					path: '/:pathMatch(.*)*',
					redirect: '/errors/404'
				}
			]
		},
		{
			path: '',
			component: () => import('@/layouts/Dashboard.vue'),
			meta: {
				isRequiredAuthorize: true
			},
			children: [
				{
					path: '/dashboard',
					name: 'dashboard',
					component: () => import('@/views/Dashboard.vue')
				},
				{
					path: '/users',
					name: 'user_list',
					component: () => import('@/views/users/UserList.vue')
				},
				{
					path: '/users/create',
					name: 'user_create',
					component: () => import('@/views/users/UserCreate.vue')
				},
				{
					path: '/users/:id',
					name: 'user_detail',
					component: () => import('@/views/users/UserDetail.vue')
				},
				{
					path: '/users/:id/edit',
					name: 'user_edit',
					component: () => import('@/views/users/UserEdit.vue')
				},
				{
					path: '/posts',
					name: 'post_list',
					component: () => import('@/views/posts/List.vue')
				},
				{
					path: '/posts/:id',
					name: 'post_detail',
					component: () => import('@/views/posts/Detail.vue')
				},
				{
					path: '/comments',
					name: 'comment_list',
					component: () => import('@/views/comment/List.vue')
				},
				{
					path: '/comments/:id',
					name: 'comment_detail',
					component: () => import('@/views/comment/Detail.vue')
				},
				{
					path: '/puzzles',
					name: 'puzzle_list',
					component: () => import('@/views/puzzle/List.vue')
				},
				{
					path: '/puzzles/create',
					name: 'puzzle_create',
					component: () => import('@/views/puzzle/Create.vue')
				},
				{
					path: '/puzzles/:puzzleId',
					name: 'puzzle_detail',
					component: () => import('@/views/puzzle/Detail.vue')
				},
				{
					path: '/puzzles/:puzzleId/edit',
					name: 'puzzle_edit',
					component: () => import('@/views/puzzle/Edit.vue')
				},
				{
					path: '/themes',
					name: 'theme_list',
					component: () => import('@/views/themes/List.vue')
				},
				{
					path: '/themes/create',
					name: 'theme_create',
					component: () => import('@/views/themes/Create.vue')
				},
				{
					path: '/themes/:id/edit',
					name: 'theme_edit',
					component: () => import('@/views/themes/Edit.vue')
				},
				{
					path: '/ng-words',
					name: 'ng_words',
					component: () => import('@/views/ngwords/Detail.vue')
				},
				{
					path: '/edit-ng-word',
					name: 'edit_ng_word',
					component: () => import('@/views/ngwords/Edit.vue')
				},
				{
					path: '/messages',
					name: 'messages_list',
					component: () => import('@/views/messages/List.vue')
				},
				{
					path: '/messages/create',
					name: 'messages_create',
					component: () => import('@/views/messages/Create.vue')
				},
				{
					path: '/messages/:messageId',
					name: 'messages_detail',
					component: () => import('@/views/messages/Detail.vue')
				},
				{
					path: '/messages/:messageId/edit',
					name: 'messages_edit',
					component: () => import('@/views/messages/Edit.vue')
				},
				{
					path: '/invite-friend-setting',
					name: 'invite_friend_setting',
					component: () => import('@/views/InviteFriendSetting.vue')
				},
				{
					path: '/reports',
					name: 'reports_list',
					component: () => import('@/views/report/List.vue')
				},
				{
					path: '/reports/:id',
					name: 'reports_detail',
					component: () => import('@/views/report/Detail.vue')
				},
				{
					path: '/invitation-codes',
					name: 'invitation_code_list',
					component: () => import('@/views/invitations/InvitationList.vue')
				},
				{
					path: '/errors/404',
					name: 'error_404',
					component: () => import('@/views/errors/404.vue')
				},
				{
					path: '/:pathMatch(.*)*',
					redirect: '/errors/404'
				}
			]
		}
	]
});

router.beforeEach((to, from, next) => {
	const signedInAdmin = JSON.parse(localStorage.getItem('signedInAdmin'));

	if (signedInAdmin === null && to.meta.isRequiredAuthorize) return next({ name: 'login' });

	if (signedInAdmin && to.name === 'login') return next({ name: 'dashboard' });

	return next();
});

router.afterEach(() => {
	const appLoading = document.getElementsByClassName('preloader');

	if (appLoading.length > 0) {
		setTimeout(() => {
			appLoading[0].classList.add('fade-out');

			setTimeout(() => {
				appLoading[0].style.display = 'none';
			}, 500);
		}, 500);
	}
});

export default router;
